import LoggerFactory from '@reedexpo/ui-logger';
import authorizationStore from '../js/common/api/authorizationStore';
import Utils from '../js/common/Utils';

let loggerInstance;

const initialiseLogger = () => {
  const service = 'Exbox-ui';
  const version = '0.1';
  const apigeeClientId = authorizationStore.getClientId();
  const url = `${Utils.getReactSettings().props.baseApiUrl}log`;

  return LoggerFactory.configure({
    url, service, version, apigeeClientId
  });
};

const getLogger = () => {
  if (!loggerInstance) {
    if (Utils.getReactSettings().props.baseApiUrl) {
      loggerInstance = initialiseLogger();
    }
  }
  return loggerInstance;
};

const extractErrors = (error) => {
  if (error instanceof Error) {
    return error.toString();
  }

  let errorSource;
  if (error.body?.errors) errorSource = error.body.errors;
  if (error.errors) errorSource = error.errors;
  if (!errorSource) return error.toString();

  return errorSource.map((err) => {
    if (err.code) { return `${err.code}${err.message ? `: ${err.message}` : ''}`; }
    if(err.extensions)
    {
      return `${err.extensions.code}`;
    }
    return JSON.stringify(err);
  }).join(', ');
};

const logError = (message, error, correlationId) => {
  const logger = getLogger();
  const extractedErrors = error ? extractErrors(error) : '';
  if (logger) {
    logger.error({ message }, extractedErrors, correlationId);
  }
};

const logErrorWithEvent = (message, error, eventContent, correlationId) => {
  const logger = getLogger();
  const extractedErrors = error ? extractErrors(error) : '';
  const content = eventContent ? JSON.stringify(eventContent) : '';
  if (logger) {
    logger.error({ message, content}, extractedErrors, correlationId);
  }
};

const logInfo = (message, error, correlationId) => {
  const logger = getLogger();
  const extractedErrors = error ? extractErrors(error) : '';
  if (logger) {
    logger.info({ message }, extractedErrors, correlationId);
  }
};

const logWarn = (message, error, correlationId) => {
  const logger = getLogger();
  const extractedErrors = error ? extractErrors(error) : '';
  if (logger) {
    logger.warn({ message }, extractedErrors, correlationId);
  }
};

const logWarnWithEvent = (message, error, eventContent, correlationId) => {
  const logger = getLogger();
  const extractedErrors = error ? extractErrors(error) : '';
  const content = eventContent ? JSON.stringify(eventContent) : '';
  if (logger) {
    logger.warn({ message, content}, extractedErrors, correlationId);
  }
};

export default {
  logError,
  logInfo,
  logWarn,
  getLogger,
  logErrorWithEvent,
  logWarnWithEvent
};
