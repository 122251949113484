import Request from '../../common/api/request';
import ServiceResultFactory from '../../common/api/ServiceResultFactory';

const getEventEditionUrl = (baseUrl, id) => `${baseUrl}/v1/event-editions/${id}`;

const getEventEdition = (baseUrl, locale, eventEditionId) => {
  const baseApiUrl = baseUrl.endsWith('/')
    ? baseUrl.substring(0, baseUrl.length - 1)
    : baseUrl;
  const url = getEventEditionUrl(baseApiUrl, eventEditionId);
  return Request.get(url)
    .then(response => ServiceResultFactory.fromSuccess(response.body))
    .catch(result => ServiceResultFactory.fromError(locale, result));
};

const getEventEditionById = (baseApiUrl, eventEditionId) => {
  const query = `{
    eventEdition(eventEditionId: "${eventEditionId}"){ 
      name
      id
      gbsCode
      defaultCurrencyCode
      eventGbsCode
      defaultCurrencyCode
      isECommerceEnabled
      inviteManagerTileEnabled
      shopTileEnabled
      badgeManagerTileEnabled
      isAtlas20Show
      isAnonymousRegistrationEnabled
      businessUnitGbsCode
      multiEventInfo {
        primaryEventEditionId
        secondaryEventEditionIds
      },
      businessUnit {
        id
      }
      brand{
        exhibitorHubUrl
        logoUrl
      }
      primaryLocale
      supportedLocales
      isParticipationEvent
      gbsEnabled
      isDashboardEnabledHistorically
      isShowCommitted
      exhibitorInsightsConfig{
        enableDashboard
        isVirtualShow
        showType
        enableProminentDisplay
        featureToggles
        basicConfig{
          digitalEngagementConfig{
            enableProductViews
            enableBrochureDownloads
          }
          leadsConfig{
            enableLeadsSection
          }
        }
      }
    } 
  }`;

  return Request.graphqlRequest(`${baseApiUrl}/graphql`, query);
};

const getEventEditionDetails = (baseApiUrl, eventEditionId) => {
  const query = `{
    eventEdition(eventEditionId: "${eventEditionId}"){ 
      id
      eventId
      name
      domain
      businessUnitGbsCode
      businessUnit {
        id
      }
      primaryLocale
      productDetailsPageUrlFormat
      isParticipationEvent
    } 
  }`;

  return Request.graphqlRequest(`${baseApiUrl}/graphql`, query);
};

const getEventEditionBusinessUnit = (baseApiUrl, eventEditionId) => {
  const query = `{
    eventEdition(eventEditionId: "${eventEditionId}"){ 
      businessUnitGbsCode
      businessUnitName
      businessUnit {
        id
      }
    } 
  }`;

  return Request.graphqlRequest(`${baseApiUrl}/graphql`, query)
              .then(response => response.body.data.eventEdition);
};

const getOfferListDetails = (baseApiUrl, eventEditionId, exhibitingOrganisationId = null) => {

  const query = `{
    eventEdition(eventEditionId: "${eventEditionId}"){ 
      offerlist(exhibitorId: "${exhibitingOrganisationId}"){
        id
        isPublished
      }
    } 
  }`;

  return Request.graphqlRequest(`${baseApiUrl}/graphql`, query);
};

export default { getEventEdition, getEventEditionById, getEventEditionDetails, getEventEditionBusinessUnit, getOfferListDetails };
