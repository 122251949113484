import Request from '../../common/api/request';
import ServiceResultFactory from '../../common/api/ServiceResultFactory';

const getExhibitorsByEventEditionAndPackageType = (baseApiUrl, eventEditionId, packageId, limit, interfaceLocale, primaryLocale) => {
  const query = `query exhibitingOrganisationByPackageType($eventEditionId: String!, $interfaceLocale: String!, $primaryLocale: String!, $limit: Int!, $packageId: Int!){
        exhibitingOrganisationByPackageType(eventEditionId: $eventEditionId, interfaceLocale: $interfaceLocale, primaryLocale: $primaryLocale, limit:$limit, packageTypeId:$packageId){
          name
          organisationGuid
          logo
          coverImage
          description
          id
          stands
        }
      }`;

  const variables = {
    eventEditionId,
    interfaceLocale,
    primaryLocale,
    limit,
    packageId
  };

  return Request.graphqlRequest(`${baseApiUrl}/graphql`, query, variables)
    .then(response => ServiceResultFactory.fromGraphqlSuccess(response, interfaceLocale, 'exhibitingOrganisationByPackageType'))
    .catch(result => ServiceResultFactory.fromError(interfaceLocale, result, true));
};

export default { getExhibitorsByEventEditionAndPackageType };
