export default  {
    dev: {
        cdnURL: 'https://css-ui-packages-eu-west-1-915203318988.s3.amazonaws.com',
    },
    show: {
        cdnURL: 'https://css-ui-packages-eu-west-1-915203318988.s3.amazonaws.com',
    },
    localAgainstShow: {
        cdnURL: 'https://css-ui-packages-eu-west-1-915203318988.s3.amazonaws.com',
    },
    preprod: {
        cdnURL: 'https://css-ui-packages-eu-west-1-324811521787.rxweb-pre.com',
    },
    prod: {
        cdnURL: 'https://css-components.rxweb-prd.com',
    }
};