import mixpanelConstants from './mixpanelConstants';
import Tracker from '../common/Tracker';

const addCommonEventProperties = (props) => {
  let eventProperties = {};
  eventProperties[mixpanelConstants.eventProperties.exhibitorId] = props.exhibitorId;
  eventProperties[mixpanelConstants.eventProperties.exhibitorName] = props.exhibitorName;
  eventProperties[mixpanelConstants.eventProperties.component] = props.component;
  eventProperties[mixpanelConstants.eventProperties.packageType] = props.packageName;
  eventProperties[mixpanelConstants.eventProperties.productArea] = mixpanelConstants.propertyValue.exhibitors;
  eventProperties[mixpanelConstants.eventProperties.viewedElement] = props.viewedElement;
  eventProperties[mixpanelConstants.eventProperties.documentName] = props.documentName;
  eventProperties[mixpanelConstants.eventProperties.documentType] = props.documentType;
  eventProperties[mixpanelConstants.eventProperties.downloadUrl] = props.downloadUrl;
  eventProperties[mixpanelConstants.eventProperties.type] = props.type;
  eventProperties[mixpanelConstants.eventProperties.contactType] = props.contactType;
  eventProperties[mixpanelConstants.eventProperties.socialMedia] = props.socialMedia;
  eventProperties[mixpanelConstants.eventProperties.sortOption] = props.sortOption;
  eventProperties[mixpanelConstants.eventProperties.tabName] = props.tab;
  eventProperties[mixpanelConstants.eventProperties.profileSection] = props.profileSection;
  eventProperties[mixpanelConstants.eventProperties.entityAdded] = props.entityAdded;
  eventProperties[mixpanelConstants.eventProperties.entityRemoved] = props.entityRemoved;
  eventProperties[mixpanelConstants.eventProperties.productId] = props.productId;
  eventProperties[mixpanelConstants.eventProperties.productName] = props.productName;
  eventProperties[mixpanelConstants.eventProperties.subComponent] = props.subComponent;
  eventProperties[mixpanelConstants.eventProperties.element] = props.element;
  eventProperties[mixpanelConstants.eventProperties.twoFactor] = props.twoFactor;
  return eventProperties;
}

const getRootMargin = (component, eventName) => {
  const headerHeight = document.querySelector('header')?.offsetHeight ? document.querySelector('header').offsetHeight : 100;
  const global_nav = document.getElementsByClassName('global-nav__base')[0]?.offsetHeight
  const rootMargin = headerHeight - (global_nav ? global_nav : 0)
  let setRootMargin = '0px';
  const components = mixpanelConstants.component;
  const eventNames = mixpanelConstants.eventName;
  if(((component === components.exhibitorDirectory || component === components.homePage) && eventName === eventNames.exhibitorImpression) ||
      (component === components.productDirectory && eventName === eventNames.productImpression)){
    setRootMargin = `-${rootMargin}px 0px 0px 0px`
  }
  return setRootMargin;
}

const trackImpressions = (eventName, props) => {
  let timer;
  const rootMargin = getRootMargin(props?.component, props?.eventName);
  return new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if(entry.isIntersecting){
        timer = setTimeout(() => {
          const otherProps = JSON.parse(entry.target.getAttribute('mixpanel-props'));
          if(otherProps){
            props = {...props, ...otherProps};
          }
          trackMixpanelEvent(eventName, props);
        }, 5000);
      }
      else{
        clearTimeout(timer);
      }
    });
  }, {
      threshold: 1,
      rootMargin: rootMargin
    });
}

const trackMixpanelEvent = (eventName, props) => {
  const tracker = Tracker.getTracker();
  if(tracker){
    const eventProperties = addCommonEventProperties(props);
    tracker.track(eventName, eventProperties);
  }
}

module.exports = {
  trackMixpanelEvent, trackImpressions, getRootMargin, addCommonEventProperties
};
