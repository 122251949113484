import Tracker from '@reedexpo/digital-tracking';
import Logger from '../logger';

class TrackerWrapper {
    constructor() {
        this.trackerInstance = new Tracker(Logger.getLogger());
    }
    getTracker() {
        return this.trackerInstance;
    }
}

export default new TrackerWrapper();
