/* global document:true */
import jwtDecode from 'jwt-decode';
import { authController } from '@reedexpo/authorization-component-ui';

const authCookieName = 'RX_AUTH';
const clientIdCookieName = 'ClientId';
const usePkce = typeof (window) === 'undefined'
  ? false
  : (window && window.authSettings
    && window.authSettings.usePkce);

const getCookies = () => {
  const cookies = {};
  if (typeof (window) === 'undefined') {
    return {};
  }
  document.cookie.split('; ').forEach((cookie) => {
    const [name, value] = cookie.split('=');
    cookies[name] = decodeURIComponent(value);
  });
  return cookies;
};

const parseJwt = () => authController
  .getToken()
  .then(jwtDecode)
  .catch(() => null);

const getToken = () => {
  if (usePkce) {
    return authController.getToken()
      .then(t => t)
      .catch(() => null);
  }
  return Promise.resolve(getCookies()[authCookieName] || null);
};

const hasClaim = async (allowAdmins, claimName, claimValue) => {
  if (usePkce) {
    return await authController.hasClaim(allowAdmins, claimName, claimValue);
  }
  const token = await getToken();
  if (!token) {
    return false;
  }
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken[claimName] === claimValue || decodedToken[claimName].includes(claimValue);
  } catch (e) {
    return false;
  }
};

const doesClaimExist = async (claimName) => {
  const token = await getToken();
  if (!token) {
    return false;
  }
  try {
    const decodedToken = jwtDecode(token);
    return !!decodedToken[claimName];
  } catch (e) {
    return false;
  }
};

export default {
  getToken,
  hasClaim,
  getClientId: () => getCookies()[clientIdCookieName] || null,
  parseJwt,
  doesClaimExist
};
